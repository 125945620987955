import React from "react";
import Layout from "../components/common/layout";
import { PageProps, graphql } from "gatsby";
import PostGrid from "../components/common/grid";
import Seo from "../components/common/seo";

const IndexPage = ({ data, location }: PageProps<Queries.HomeQuery>) => {
  return (
    <Layout isHome={true} isWide={true}>
      <Seo
        title={`Home - ${data.site?.siteMetadata?.title}`}
        origin={location.origin}
      />
      <PostGrid posts={data.posts} />
    </Layout>
  );
};

export default IndexPage;

export const homeQuery = graphql`
  query Home {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___pubDate], order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          altText
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

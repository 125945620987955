import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import Masonry from "react-masonry-css";

const PostGrid = ({ posts }: Props) => {
  const childElements: JSX.Element[] = posts.nodes.map((post, i) => (
    <div className="overflow-hidden" key={i}>
      <Link
        to={`${post.fields?.slug}`}
        className="hover:opacity-70 opacity-100 duration-[1000ms]"
      >
        <GatsbyImage
          //@ts-ignore
          image={post.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          alt=""
          className="hover:scale-105 scale-100 duration-[5000ms] transition-transform"
        />
        <span className="sr-only">{post.frontmatter?.title}</span>
      </Link>
    </div>
  ));

  const breakpointColsObj = {
    default: 5,
    1024: 3,
    768: 2,
    320: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointColsObj}
      className="masonry-grid"
      columnClassName="masonry-grid_col"
    >
      {childElements}
    </Masonry>
  );
};

export default PostGrid;

type Props = {
  posts: {
    readonly nodes: readonly {
      readonly fields: {
        readonly slug: string | null;
      } | null;
      readonly frontmatter: {
        readonly title: string | null;
        readonly altText: string | null;
        readonly image: {
          readonly childImageSharp: {
            readonly gatsbyImageData: IGatsbyImageData;
          } | null;
        } | null;
      } | null;
    }[];
  };
};
